<template>
  <page-header-wrapper title="基本信息">
    <template v-slot:content>
      <a-descriptions size="small" :column="isMobile ? 1 : 2">
        <a-descriptions-item label="任务名称">{{ tasksData.name }}</a-descriptions-item>
        <a-descriptions-item label="楼盘">{{ tasksData.houseName }}</a-descriptions-item>
        <a-descriptions-item label="开始日期">{{ tasksData.startTime }}</a-descriptions-item>
        <a-descriptions-item label="总套数">
          {{ tasksData.total }}
        </a-descriptions-item>
        <a-descriptions-item label="结束日期">{{ tasksData.endTime }}</a-descriptions-item>
        <a-descriptions-item label="合同">
          <div v-if="contract.length === 0">
            暂无合同
          </div>
          <div v-else>
            <div class="contract" v-for="item in contract" :key="item.id">
              <span class="contract_item">{{ item.title }}</span
              ><a :href="item.path">下载</a>
            </div>
          </div>          
        </a-descriptions-item>
        <a-descriptions-item label="特色卖点">{{ tasksData.feature }}</a-descriptions-item>
      </a-descriptions>
    </template>

    <a-card style="margin-top: 24px" :bordered="false" title="报备规则">
      <a-descriptions>
        <a-descriptions-item label="报备开始时间">{{ tasksData.reportStartTime }}</a-descriptions-item>
        <a-descriptions-item label="报备到访时间">{{ tasksData.createTime }}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>
        <a-descriptions-item label="到访保护期">{{ `${tasksData.protectDays}天` }}</a-descriptions-item>
        <a-descriptions-item label="到访时间">08:30 - 20:00</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>
        <a-descriptions-item label="老带新到访保护期">{{ tasksData.clientOrderProtectDays ? `${tasksData.clientOrderProtectDays}天` : '未设置' }}</a-descriptions-item>
        <a-descriptions-item label="老带新报备有效期">{{ tasksData.clientOrderValidDays ? `${tasksData.clientOrderValidDays}天` : '未设置' }}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>
        <a-descriptions-item label="备注">{{ tasksData.reportRemark }}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card style="margin-top: 24px" :bordered="false" title="定向发布">
      <a-list :grid="{ gutter: 16, column: 4 }" :data-source="orienteering">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-card :title="item.title">
            {{ item.tel }}
          </a-card>
        </a-list-item>
      </a-list>
    </a-card>

    <!-- 操作 -->
    <a-card
      style="margin-top: 24px"
      :bordered="false"
      :tabList="operationTabList"
      :activeTabKey="operationActiveTabKey"
      @tabChange="
        key => {
          this.operationActiveTabKey = key
        }
      "
    >
      <a-table
        v-if="operationActiveTabKey === '1'"
        :columns="operationColumns"
        :dataSource="operation1"
        :pagination="false"
      >
        <template slot="status" slot-scope="status">
          <a-badge :status="status | statusTypeFilter" :text="status | statusFilter" />
        </template>
      </a-table>
      <a-table
        v-if="operationActiveTabKey === '2'"
        :columns="operationColumns"
        :dataSource="operation2"
        :pagination="false"
      >
        <template slot="status" slot-scope="status">
          <a-badge :status="status | statusTypeFilter" :text="status | statusFilter" />
        </template>
      </a-table>
      <a-table
        v-if="operationActiveTabKey === '3'"
        :columns="operationColumns"
        :dataSource="operation3"
        :pagination="false"
      >
        <template slot="status" slot-scope="status">
          <a-badge :status="status | statusTypeFilter" :text="status | statusFilter" />
        </template>
      </a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { baseMixin } from '@/store/app-mixin'
import { findByIdTasks } from '@/api/tasks'
import { findByIdHouse } from '@/api/house'
import { getDevelopersContract } from '@/api/developers'

export default {
  name: 'Advanced',
  mixins: [baseMixin],
  data() {
    return {
      id: '',
      developerId: '',
      tasksData: {},
      operationTabList: [
        {
          key: '1',
          tab: '操作日志一'
        }
      ],
      operationActiveTabKey: '1',

      operationColumns: [
        {
          title: '操作类型',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: '操作人',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '执行结果',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作时间',
          dataIndex: 'updatedAt',
          key: 'updatedAt'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark'
        }
      ],
      operation1: [
        // {
        //   key: 'op1',
        //   type: '任务重新打开',
        //   name: '曲丽丽',
        //   status: 'agree',
        //   updatedAt: '2017-10-03  19:23:12',
        //   remark: '-'
        // },
        // {
        //   key: 'op2',
        //   type: '任务关闭',
        //   name: '付小小',
        //   status: 'reject',
        //   updatedAt: '2017-10-03  19:23:12',
        //   remark: '不通过原因'
        // },
        // {
        //   key: 'op3',
        //   type: '任务通过',
        //   name: '周毛毛',
        //   status: 'agree',
        //   updatedAt: '2017-10-03  19:23:12',
        //   remark: '-'
        // },
        // {
        //   key: 'op4',
        //   type: '任务申请',
        //   name: '林东东',
        //   status: 'agree',
        //   updatedAt: '2017-10-03  19:23:12',
        //   remark: '很棒'
        // },
        // {
        //   key: 'op5',
        //   type: '任务被打回',
        //   name: '汗牙牙',
        //   status: 'agree',
        //   updatedAt: '2017-10-03  19:23:12',
        //   remark: '-'
        // },
        // {
        //   key: 'op6',
        //   type: '任务创建&申请',
        //   name: '汗牙牙',
        //   status: 'agree',
        //   updatedAt: '2017-10-03  19:23:12',
        //   remark: '-'
        // }
      ],
      // 定向发布
      orienteering: [
        // {
        //   title: '张经纪',
        //   tel: 17012154621
        // },
        // {
        //   title: '王经纪 2',
        //   tel: 17012154611
        // },
        // {
        //   title: '李经纪 3',
        //   tel: 17012154621
        // },
        // {
        //   title: '王经纪 4',
        //   tel: 17012154221
        // }
      ],
      // 合同
      contract: []
    }
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        agree: '成功',
        reject: '驳回'
      }
      return statusMap[status]
    },
    statusTypeFilter(type) {
      const statusTypeMap = {
        agree: 'success',
        reject: 'error'
      }
      return statusTypeMap[type]
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  mounted() {
    const requestParameters = {
      id: this.id
    }
    findByIdTasks(requestParameters)
      .then(res => {
        this.tasksData = { ...res }
        console.log(res, 'tasksData')
        console.log(this.tasksData, 'this.tasksData')
        const houseId = this.tasksData.houseId
        findByIdHouse(houseId).then(res1 => {
          this.developerId = res1.developer.id
          this.fetchContract()
        })
      })
  },
  methods: {
    fetchContract() {
      getDevelopersContract(this.developerId).then(res => {
        console.log(res, 'ressss');
        this.contract = res._embedded.medias.map(item => {
          console.log(item, 'item');
          item = {
            title: item.path.slice(item.path.lastIndexOf('/') + 1),
            path: this.host + item.path
          }
          return item
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail-layout {
  margin-left: 44px;
}
.text {
  color: rgba(0, 0, 0, 0.45);
}

.heading {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
}

.no-data {
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  line-height: 64px;
  font-size: 16px;

  i {
    font-size: 24px;
    margin-right: 16px;
    position: relative;
    top: 3px;
  }
}

.mobile {
  .detail-layout {
    margin-left: unset;
  }
  .status-list {
    text-align: left;
  }
}
</style>
